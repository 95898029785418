<template>
  <div style="height: 100vh;background-color: #F6F6F6;" v-loading="fullscreenLoading">
    <div class="top-content">
      <p class="today-income">可提现金额(元)</p>
      <p class="income-text">{{ income_text }}</p>
    </div>
    <div class="cash-content">
      <p class="cash-label">提现金额</p>
      <van-field
          v-model="cash"
          name="金额"
          label="￥"
          type="number"
          class="cash-field"
          :border="true"
          placeholder="请输入提现金额"
          use-button-slot
      >
        <van-button slot="button"
                    size="small"
                    type="plain"
                    class="cash-button" @click.native="getAll">全部提现
        </van-button>

      </van-field>
      <van-field
          v-model="acctNo"
          name="提现至"
          label="提现至"
          :border="true"
          disabled="disabled"
          placeholder="结算账户">
      </van-field>
      <van-button class="confirm-button" @click.native="confirmCash">申 请 提 现</van-button>
      <van-button class="history-button" @click.native="getCashHistory">提 现 历 史</van-button>

    </div>
  </div>
</template>

<script>
import {Dialog} from "vant";

export default {
  name: "getcash",
  data() {
    return {
      cash: "",
      income_text: "0.00",
      balance: "0.00",
      acctNo: "",
      fullscreenLoading: false,
    }

  },
  methods: {
    getAll() {
      this.cash = this.income_text;
    },
    getCashHistory() {
        this.$router.push({path:'/h5/getcash_history'});
    },
    confirmCash() {
      if (parseFloat(this.cash) > parseFloat(this.income_text)) {
        this.$message.info("提现金额不能超过余额。")
        this.getAll()
        return;
      }
      if (parseFloat(this.cash) < 0.01) {
        this.$message.info("提现金额最小为0.01元。")
        return;
      }
      let _this = this;
      Dialog.confirm({
        title: '提示',
        message:
            '是否提现 ' + _this.cash + " 元?",
      })
          .then(() => {
            _this.outPay()
          })
          .catch(() => {
            // on cancel
          });
    },
    getDetailData() {
      //
      let _this = this;
      Http.get('/frontend/merchant/getMerchantDetail').then(result => {
        if (result && result.code === 200) {
          let acctNo = result.data.acctNo;
          if (!acctNo) {
            this.$message.error("您尚未绑定结算账户，无法继续提现。")
            return;
          }
          let pre = acctNo.substr(0, 4)
          let suf = acctNo.substr(acctNo.length - 4, acctNo.length - 1)
          _this.acctNo = pre + "****" + suf;
          // console.log(result)
        } else {
          _this.$message.error(result.message)
        }
      }).catch(error => {
        this.$message.error('请求失败')
      })
    },
    getBalanceData() {
      //
      let _this = this;
      Http.get('/frontend/merchant/getBalance').then(result => {
        if (result && result.code === 200) {
          _this.income_text = result.data.balance;
          // console.log(result)
        } else {
          _this.$message.error(result.message)
        }
      }).catch(error => {
        this.$message.error('请求失败')
      })
    },
    outPay() {
      let _this = this;
      this.fullscreenLoading = true;
      let request_uri = "/frontend/merchant/outPayment";
      let params = {
        amount: this.cash,
      }
      Http.post(request_uri, params).then(result => {
        if (result.code === 200) {
          _this.$message.success("提现成功！")
        } else {
          _this.$message.error(result.message || "查询失败！")
        }
        _this.fullscreenLoading = false

      }).catch(error => {
        // console.log(error)
        // _this.$message.error('请求失败')
        _this.fullscreenLoading = false
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    }
  },
  mounted() {
    this.getDetailData()
    this.getBalanceData()
  }
}
</script>

<style scoped>
body {
  background-color: #F6F6F6;
}

.top-content {
  width: 100vw;
  height: 162px;
  background: #DABF6D;
  /*opacity: 0.7;*/
  padding-left: 33px;
  padding-top: 38px;
}

.today-income {
  font-size: 12px;
}

.income-text {
  width: 100vw;
  height: 34px;
  font-family: PingFang SC;
  font-size: 30px;
  font-weight: 600;
  color: #000000;
  line-height: 30px;
}

.cash-button {
  width: 111px;
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #2473C5;
  line-height: 20px;
  border: none;
}

.history-button {
  width: 230px;
  height: 40px;
  background: transparent;
  border: none;
  border-radius: 20px;
  margin-left: calc(46vw - 115px);
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #2069BE;
  line-height: 15px;
  margin-top: 12px;
}

.cash-content {
  width: 92vw;
  margin-top: -30px;
  margin-left: 4vw;
  height: 450px;
  background: #FFFFFF;
  border-radius: 6px;
}

/deep/ .cash-field .van-cell__title {
  font-size: 20px;
  text-align: center;
  width: 4.4em;
}

.cash-label {
  width: 110px;
  height: 27px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: 20px;
}

.confirm-button {
  width: 230px;
  height: 40px;
  background: #E9BE3E;
  border: 2px solid #E9BE3E;
  border-radius: 20px;
  margin-left: calc(46vw - 115px);
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 15px;
  margin-top: 32px;
}

/deep/ .van-cell {
  padding-top: 32px;
  padding-bottom: 32px;
}

/deep/ .van-field__control:disabled {
  color: #323233;
  -webkit-text-fill-color: #323233;
}

/deep/ .van-field--disabled .van-field__label {
  color: #323233;
}
</style>